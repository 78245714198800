import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import logo from "../../images/newLogo.png";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
} from "./Navbar.elements";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to='/#home' onClick={closeMobileMenu}>
              <NavIcon src={logo} alt='logoa' />
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to='/#home' onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/#angebot' onClick={closeMobileMenu}>
                  Angebot
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/#überuns' onClick={closeMobileMenu}>
                  Über uns
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/galerie' onClick={closeMobileMenu}>
                  Galerie
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/#kontakt' onClick={closeMobileMenu}>
                  Kontakt
                </NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
