import React from "react";

import { Container } from "../../globalStyles";
import {
  InfoSec,
  TextWrapperTwo,
  HeadingTwo,
  SubtitleTwo,
} from "../InfoSection/InfoSection.elements";

function Datensh() {
  return (
    <>
      <InfoSec>
        <Container>
          <TextWrapperTwo>
            <HeadingTwo>Datenschutzerklärung</HeadingTwo>
            <SubtitleTwo>
              <p>
                Diese Datenschutzerklärung klärt Nutzerinnen und Nutzer über die
                Art, den Umfang und Zweck der Erhebung und Verwendung
                personenbezogener Daten durch den verantwortlichen Betreiber.
              </p>
              <br />
              <p>
                Der Betreiber dieser Seite übernimmt keinerlei Gewähr
                hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit,
                Aktualität, Zuverlässigkeit und Vollständigkeit der
                Informationen.
              </p>
              <br />
              <p>
                Alle Angebote sind unverbindlich. Der Anbieter behält sich vor,
                Teile der Seiten der Homepage oder des Angebots ohne gesonderte
                Ankündigung zu verändern, zu ergänzen, zu löschen oder die
                Veröffentlichung zeitweise oder ganz einzustellen.
              </p>
              <br />
              <p>
                Wir nehmen den Schutz Ihrer persönlichen Daten ernst und
                behandeln diese vertraulich. Die Nutzung unserer Webseite ist in
                der Regel ohne Angaben personenbezogener Daten möglich. Soweit
                personenbezogene Daten (z.B. Name, E-Mail-Adresse,
                Telefonnummer) auf unserer Webseite erhoben werden, erfolgt
                dies, soweit möglich, auf freiwilliger Basis. Diese Daten werden
                ohne Ihre Zustimmigkeit nicht an Dritte weitergegeben. Bei der
                Kontaktaufnahme mit dem Anbieter (z.B. per Kontaktformular oder
                E-Mail) werden die Angaben des Nutzers zwecks Bearbeitung der
                Anfrage sowie für den Fall, dass Anschlussfragen entstehen,
                gespeichert. Wir weisen Sie darauf hin, dass die
                Datenübertragung im Internet (z.B. per E-Mail) Sicherheitslücken
                aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                durch Dritte ist nicht möglich.
              </p>
              <br />
              <p>
                Haftungsansprüche gegen den Anbieter dieser Seite wegen
                materieller oder immaterieller Schäden, welche aus dem Zugriff
                oder Nutzung bzw. Nichtnutzung der veröffentlichten
                Informationen, durch Missbrauch der Verbindung oder durch
                technische Störungen entstanden sind, werden ausgeschlossen.
              </p>
              <br />
              <p>
                Links und Verweise auf Webseiten Dritter liegen ausserhalb
                unseres Verantwortungsbereichs. Die Nutzung und der Zugriff
                dritter Webseiten erfolgt auf eigene Gefahr des Nutzers. Wir
                lehnen jegliche Verantwortung für solche Webseiten ab. Für den
                Inhalt der verlinkten Seiten sind ausschliesslich deren
                Betreiber verantwortlich.
              </p>
              <br />
              <p>
                Die Urheber- und alle anderen Rechte an Inhalte, Texte, Bilder,
                Fotos, Videos oder andere Dateien auf der Webseite gehören
                ausschliesslich der Milas-SauberZauber oder den speziell
                genannten Rechtsinhabern. Die Übernahme und Nutzung der Dateien
                ist untersagt. Für die Reproduktion jeglicher Elemente ist die
                schriftliche Zustimmung der Urheberrechtsträger im Voraus
                einzuholen.
              </p>
            </SubtitleTwo>
          </TextWrapperTwo>
        </Container>
      </InfoSec>
    </>
  );
}

export default Datensh;
