import React, { useState } from 'react';
import { Container, Button } from '../../globalStyles';
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  ImgWrapper,
  InfoColumnDiv,
  Img,
  Heading,
} from '../InfoSection/InfoSection.elements';
import img from '../../images/19197471.webp';

function ContactSection() {
  const [formData, setFormData] = useState({
    'bot-field': '',
    name: '',
    email: '',
    phone: '',
    comment: '',
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: encode({
        'form-name': 'contact',
        ...formData,
      }),
    })
      .then(() =>
        alert(
          'Danke für Ihre Nachricht! Wir werden uns in Kürze mit Ihnen in Verbindung setzen.'
        )
      )
      .catch((error) => alert(error));

    // Optionally reset the form
    setFormData({
      'bot-field': '',
      name: '',
      email: '',
      phone: '',
      comment: '',
    });
  };

  return (
    <InfoColumnDiv id='kontakt'>
      <InfoSec lightBg>
        <Container>
          <InfoRow>
            <InfoColumn>
              <TextWrapper>
                <Heading lightText>Kontaktieren Sie uns</Heading>
                <form
                  name='contact'
                  method='POST'
                  data-netlify='true'
                  data-netlify-honeypot='bot-field'
                  onSubmit={handleSubmit}
                >
                  <input type='hidden' name='form-name' value='contact' />
                  <p hidden>
                    <label>
                      Nicht ausfüllen:{' '}
                      <input
                        name='bot-field'
                        value={formData['bot-field']}
                        onChange={handleChange}
                      />
                    </label>
                  </p>
                  <input
                    type='text'
                    name='name'
                    placeholder='Name'
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type='email'
                    name='email'
                    placeholder='E-Mail'
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type='text'
                    name='phone'
                    placeholder='Telefon'
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                  <textarea
                    rows='4'
                    name='comment'
                    placeholder='Ihre Nachricht'
                    value={formData.comment}
                    onChange={handleChange}
                    required
                  />
                  <Button
                    big
                    fontBig
                    variant='outline-light'
                    id='action-btn'
                    type='submit'
                  >
                    Einreichen
                  </Button>
                </form>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start>
                <Img src={img} alt='Kontaktbild' />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </InfoColumnDiv>
  );
}

export default ContactSection;
