import styled from "styled-components";

export const InfoSec = styled.div`
  color: #fff;
  padding: 130px 0;
  background: #3293c6;
  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;
export const TextWrapperTwo = styled.div`
  max-width: auto;
  padding-top: 0;
  padding-left: 3rem;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
    padding-left: 1.5rem;
  }
`;
export const TextWrapperReferenzen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
    padding-left: 1.5rem;
  }
`;
export const TextWrapperError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 65px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
    padding-left: 1.5rem;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
`;

export const TopLine = styled.div`
  color: #fff;
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;
`;
export const HeadingTwo = styled.h1`
  margin-bottom: 24px;
  font-size: 4rem;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
export const HeadingThree = styled.h1`
  margin-bottom: 24px;
  font-size: 2.5rem;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;
  padding-right: 85px;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
export const HeadingError = styled.h1`
  margin-bottom: 24px;
  font-size: 4rem;
  line-height: 1.1;
  font-weight: 600;
  color: black;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
`;

export const SubtitleTwo = styled.p`
  max-width: auto;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
`;
export const AnchorTags = styled.a`
  color: #fff;
  cursor: pointer;
  text-decoration: none;
`;
export const AnchorTagsDiv = styled.div`
  padding-left: 110px;
  max-width: auto;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    padding-left: 0px;
  }
`;

export const InfoColumnDiv = styled.div`
  height: auto;
  position: relative;
  display: block;
  text-align: center;
  overflow-y: hidden;

  input,
  textarea {
    border: 1px solid black;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 15px;
    margin: 0 auto 10px auto;
    width: 100%;
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    color: black;
  }

  ::placeholder {
    color: white;
    opacity: 0.6;
  }
`;

export const TopAngebot = styled.div`
  .register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 7px;
    width: 160px;
    height: 160px;

    opacity: 0.8;
    font-size: 0.8rem;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    /* Rounded corners to create circle */
    border-radius: 100%;
    text-shadow: 0 1px 0 #000;
    transform: rotate(2deg);
    background: -ms-linear-gradient(#a80000, #740404);
    background: linear-gradient(#a80000, #740404);

    /* TODO: Transition the transform property over 1 second */
    transition: transform 1s;
    @media screen and (max-width: 768px) {
      padding: 3px;
      width: 110px;
      height: 110px;
      font-size: 0.5rem;
    }
  }

  .register:hover {
    color: #fff;
    background: -ms-linear-gradient(#bc0101, #8c0909);
    background: linear-gradient(#bc0101, #8c0909);

    /* TODO: rotate to 16 degrees and scale by 1.1 */
    transform: rotate(16deg) scale(1.1, 1.1);

    /* TODO: Transition the transform property over 1 second */
    transition: transform 1s;
  }

  .register:before /* Creates dotted border */ {
    display: block;
    position: absolute;
    top: -7px;
    right: -7px;
    height: 168px;
    width: 168px;
    content: "";
    border: 3px dotted #740404;
    border-radius: 100%;
    @media screen and (max-width: 768px) {
      border: none;
    }
  }

  .register .free {
    font-size: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;
