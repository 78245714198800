import React, { useState } from 'react';
import 'react-18-image-lightbox/style.css';
import Lightbox from 'react-18-image-lightbox';
import images from './data';
import './Lightbox.css';

function LightboxPage() {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };



  return (
    <div>
      <div className='container'>
        {images.map((image, index) => (
          <div key={image.id} className='image-card'>
            <img
              className='image'
              src={image.imageName}
              alt=''
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].imageName}
          nextSrc={images[(photoIndex + 1) % images.length].imageName}
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length].imageName
          }
          onCloseRequest={() => setIsOpen(false)} // Close lightbox
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}

export default LightboxPage;
