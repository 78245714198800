import React from "react";
import { NavHashLink as Link } from "react-router-hash-link";
import { Container, Button } from "../../globalStyles";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  TopAngebot,
} from "./InfoSection.elements";
import img from "../../images/29299.webp";

function InfoSection() {
  return (
    <>
      <div id='home'>
        <InfoSec lightBg>
          <Container>
            <InfoRow imgStart>
              <InfoColumn>
                <TextWrapper>
                  <TopLine lightTopLine>Reinigungsservice</TopLine>
                  <Heading lightText>
                    Herzlich Willkommen auf der Homepage von <br />
                    Milas-SauberZauber
                  </Heading>
                  <Subtitle lightTextDesc>
                    <p>
                      Sie können sich darauf verlassen, dass wir Ihren Auftrag
                      wunschgemäss nach Ihren Bedürfnissen behandeln.
                    </p>

                    <p>
                      Buchen Sie uns und überzeugen Sie sich. Wir freuen uns,
                      Ihnen unsere Dienstleistungen anzubieten.
                    </p>
                  </Subtitle>
                  <Link to='/#kontakt'>
                    <Button big fontBig>
                      Kontaktieren Sie uns
                    </Button>
                  </Link>
                </TextWrapper>
              </InfoColumn>
              <InfoColumn>
                <ImgWrapper>
                  <TopAngebot>
                    <a class='register' href='/#kontakt'>
                      <h2>Top </h2>
                      <h2>Dienstleistung</h2>
                    </a>
                  </TopAngebot>
                  <Img src={img} alt='img' />
                </ImgWrapper>
              </InfoColumn>
            </InfoRow>
          </Container>
        </InfoSec>
      </div>
    </>
  );
}

export default InfoSection;
