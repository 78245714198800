import React from 'react';
import GlobalStyle from './globalStyles';
import {
  InfoSection,
  ContactSection,
  AboutUsSection,
  LightboxPage,
  Angebot,
  Agb,
  Datensh,
  Impressum,
  Navbar,
  Footer,
} from './components';
import Error from './components/ErrorPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

function MainPage(props) {
  return (
    <div className='App'>
      <InfoSection />
      <Angebot />
      <AboutUsSection />
      <ContactSection />
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='/galerie' element={<LightboxPage />} />
        <Route path='/agb' element={<Agb />} />
        <Route path='/datenschutzerklarung' element={<Datensh />} />
        <Route path='/impressum' element={<Impressum />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  );
}
