import React from "react";

import logo from "../../images/newLogo.png";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import {
  FooterContainer,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIconLink,
  SocialMedia,
  SocialMediaWrap,
  SocialFooter,
} from "./Footer.elements";

function Footer() {
  return (
    <FooterContainer>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Luisa Shakjir</FooterLinkTitle>
            <FooterLink href='tel: 0763295700'>
              Telefon: 076 329 57 00
            </FooterLink>

            <FooterLink href='mailto:milas-sauberzauber@gmx.ch'>
              E-Mail: milas-sauberzauber@gmx.ch
            </FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Mislim Shakjir</FooterLinkTitle>
            <FooterLink href='tel: 0787123929'>
              Telefon: 078 712 39 29
            </FooterLink>

            <FooterLink href='mailto:milas-sauberzauber@gmx.ch'>
              E-Mail: milas-sauberzauber@gmx.ch
            </FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer>

      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/#home'>
            <SocialIcon src={logo} alt='logoa' />
          </SocialLogo>
          <SocialIconLink
            href='https://www.facebook.com/Milas-SauberZauber-1284411528311607'
            target='_blank'
            aria-label='Facebook'
          >
            <FaFacebook />
          </SocialIconLink>
          <SocialIconLink
            href='https://www.google.com/maps/place/Milas-SauberZauber/@47.0627649,7.6327792,15z/data=!4m2!3m1!1s0x0:0xe647f263cc1a5095?sa=X&ved=2ahUKEwjn9_6bipj0AhVkhv0HHTe6AuEQ_BJ6BAg-EAU'
            target='_blank'
            aria-label='Google'
          >
            <FaGoogle />
          </SocialIconLink>
          <WebsiteRights>© 2021</WebsiteRights>
          <SocialFooter to='/agb'>AGB</SocialFooter>
          <SocialFooter to='/impressum'>Impressum</SocialFooter>
          <SocialFooter to='/datenschutzerklarung'>
            Datenschutzerklärung
          </SocialFooter>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
