import React from 'react';

import { Container } from '../../globalStyles';
import {
  InfoSec,
  SubtitleTwo,
  TextWrapperReferenzen,
  HeadingThree,
} from '../InfoSection/InfoSection.elements';

function Datensh() {
  return (
    <>
      <InfoSec>
        <Container>
          <TextWrapperReferenzen>
            <HeadingThree>Impressum</HeadingThree>
            <SubtitleTwo>
              <p>Milas-SauberZauber</p>
              <p>Herr Mislim Shakjir</p>
              <p>Lorraine 6a</p>
              <p>3400 Burgdorf</p>
              <p>Tel: 078 712 39 29</p>
              <p>E-Mail: milas-sauberzauber@gmx.ch</p>
            </SubtitleTwo>
          </TextWrapperReferenzen>
        </Container>
      </InfoSec>
    </>
  );
}

export default Datensh;
